import React from "react";
import CarCard from "./CarCard";
import { Row, Col, Card, Container } from "react-bootstrap";
import { ShimmerCarCardCargando } from "../widgets/miscelaneos";

export default function CatalogoLayoutNuevos(props) {

    const { post, isLoading, parameters} = props;

    if (isLoading) {
        return (
            <ShimmerCarCardCargando />
        )
    }

    if (!post || post.length === 0) {
        return (
            <div
                style={{
                    textAlign: "center",
                    minHeight: 400,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white"
                }}
            >
                <h5>No se encontraron autos.</h5>
            </div>
        );
    }


    const pintaCarCards = post.map((obj, i) => {
        let slugNew = ``;
        if (obj.make === "CHEVROLET") {
            //console.log("Aqui entran CHEVROLET")
            slugNew = `/nuevos/${obj.slug}`;
        } else if (obj.make === "NISSAN") {
            //console.log("Aqui entran NISSAN")
            slugNew = `/nissan/${obj.slug}${parameters}`;
        } else if (obj.make === "BUICK") {
            //console.log("Aqui entran BUOCK")
            slugNew = `/buick/${obj.slug}${parameters}`;
        } else if (obj.make === "CADILLAC") {
            //console.log("Aqui entran CADILLAC")
            slugNew = `/cadillac/${obj.slug}${parameters}`;
        } else if (obj.make === "GMC") {
            //console.log("Aqui entran GMC")
            slugNew = `/gmc/${obj.slug}${parameters}`;
        }
        // console.log("slug NEw : ", slugNew);
        return (
            <Col key={i} px={2} xs={12} className="mb-3 px-2 col-6">
                <CarCard
                    image={obj.image}
                    name={obj.name}
                    title={obj.model}
                    year={obj.year}
                    brand={obj.make}
                    price={obj.price}
                    model={obj.model}
                    url={``}
                    linksTo={slugNew}
                    parameters={parameters}
                />
            </Col>
        )
    })


    return (
        <>
            <Row xl={3} lg={3} md={2} sm={1} xs={1} className="px-2">
                {pintaCarCards}
            </Row>
        </>
    )

}