import React, { useEffect, useState } from "react";
import { Container, Col, Row, Card } from "react-bootstrap";
import api from "../../actions/riveroApi";

import ChevAuto from "../../images/iconosFiltroNew/chevy_autos.png";
import ChevAutoA from "../../images/iconosFiltroNew/chevy_autos_activo.png";
import ChevPickup from "../../images/iconosFiltroNew/chevy_pickups.png";
import ChevPickupA from "../../images/iconosFiltroNew/chevy_pickups_activo.png";
import ChevSuvs from "../../images/iconosFiltroNew/chevy_suvs.png";
import ChevSuvsA from "../../images/iconosFiltroNew/chevy_suvs_activo.png";
import ChevDeportivos from "../../images/iconosFiltroNew/chevy_deportivos.png";
import ChevDeportivosA from "../../images/iconosFiltroNew/chevy_deportivos_activo.png";

import NissanAuto from "../../images/iconosFiltroNew/nissan_autos.png";
import NissanAutoA from "../../images/iconosFiltroNew/nissan_autos_act.png";
import NissanPickup from "../../images/iconosFiltroNew/nissan_pickups.png";
import NissanPickupA from "../../images/iconosFiltroNew/nissan_pickups_act.png";
import NissanSuvs from "../../images/iconosFiltroNew/nissan_suvs.png";
import NissanSuvsA from "../../images/iconosFiltroNew/nissan_suvs_act.png";
import NissanDeportivos from "../../images/iconosFiltroNew/nissan_deportivos.png";
import NissanDeportivosA from "../../images/iconosFiltroNew/nissan_deportivos_act.png";

import OtrosAuto from "../../images/iconosFiltroNew/otros_autos.png";
import OtrosAutoA from "../../images/iconosFiltroNew/otros_autos_actvo.png";
import OtrosPickup from "../../images/iconosFiltroNew/otros_pickups.png";
import OtrosPickupA from "../../images/iconosFiltroNew/otros_pickups_actvo.png";
import OtrosSuvs from "../../images/iconosFiltroNew/otros_suvs.png";
import OtrosSuvsA from "../../images/iconosFiltroNew/otros_suvs_actvo.png";
import OtrosDeportivos from "../../images/iconosFiltroNew/otros_deportivos.png";
import OtrosDeportivosA from "../../images/iconosFiltroNew/otros_deportivos_actvo.png";

export default function FiltroNuevos({onFilter, colorModal, filterMarcas, navegante}){
    const [splitUrl, setSplitUrl] = useState([]);

  useEffect(() => {
    setSplitUrl(window.location.pathname.split("/"));
  }, []);
    /* console.log(splitUrl[5]) */

    const typesImages = [];

    if (splitUrl[1] == "chevrolet"){
        typesImages.push({ image: ChevAuto, imageChosen: ChevAutoA })
        typesImages.push({ image: ChevSuvs, imageChosen: ChevSuvsA })
        typesImages.push({ image: ChevPickup, imageChosen: ChevPickupA })
        typesImages.push({ image: ChevDeportivos, imageChosen: ChevDeportivosA })
    } else if (splitUrl[1] == "nissan"){
        typesImages.push({ image: NissanAuto, imageChosen: NissanAutoA })
        typesImages.push({ image: NissanSuvs, imageChosen: NissanSuvsA })
        typesImages.push({ image: NissanPickup, imageChosen: NissanPickupA })
        typesImages.push({ image: NissanDeportivos, imageChosen: NissanDeportivosA })
    } else if (splitUrl[1] == undefined){
        typesImages.push({ image: OtrosAuto, imageChosen: OtrosAutoA })
        typesImages.push({ image: OtrosSuvs, imageChosen: OtrosSuvsA })
        typesImages.push({ image: OtrosPickup, imageChosen: OtrosPickupA })
        typesImages.push({ image: OtrosDeportivos, imageChosen: OtrosDeportivosA })
    }

    const types = ["Autos", "Suvs", "PickUps y Van", "Deportivos y Eléctricos"];
    const makes = ["CHEVROLET", "GMC", "CADILLAC", "BUICK"];

    let mappingTipos;
    let mappingMarcas;

    if (filterMarcas[0] != "NISSAN") {
        mappingTipos = types.map((obj, i) => {
            return(
                <Col key={i} className="p-1">
                    <div onClick={() => navegante(obj, "Tipos")} style={{ minHeight: "50px", cursor: 'pointer' }}>
                        <picture style={{width: "100%", height: "100%"}}>
                        <img
                            src={typesImages[i]?.image}
                            alt="checkbox"
                            className="w-100"
                            loading="lazy"
                            onMouseOver={(e) => (e.currentTarget.src = typesImages[i]?.imageChosen)}
                            onMouseOut={(e) => (e.currentTarget.src = typesImages[i]?.image)}
                        />
                        </picture>
{/*                         <div className={`text-center`} style={{ lineHeight: "15px" }}>
                        <span className={colorModal === true ? "text-filtro-seleccion-black" : "text-filtro-seleccion"}>
                            {obj}
                        </span>
                        </div> */}
                    </div>
                </Col>
            )
        });
        
        /* mappingMarcas = makes.map((obj, i) => {
            return(
                <Col lg={2} xs={5} key={i} className={filterMarcas && filterMarcas.indexOf(obj) !== -1 ? "px-1 m-1 boton-filtronuevo-check" : "px-1 m-1 boton-filtronuevo"}>
                    <div className="form-group form-check" style={{position: 'absolute'}}>
                        <input 
                        type="checkbox" 
                        className="form-check-input rounded " 
                        id={`exampleCheckMarcas${i}`} 
                        checked={filterMarcas && filterMarcas.indexOf(obj) !== -1 } 
                        onClick={() => onFilter(obj, "Marcas")}/>
                    </div>
                        <label className="form-check-label" for={`exampleCheckMarcas${i}`} style={{position: 'relative'}}>
                            <img
                                src={`https://d3s2hob8w3xwk8.cloudfront.net/makes/${obj.toLowerCase()}.png`}
                                alt="checkbox"
                                className="w-100"
                                loading="lazy"
                            />
                            <div className={`text-center`} style={{ lineHeight: "15px" }}>
                                <span className={"text-filtro-seleccion"}>
                                    {obj}
                                </span>
                            </div>
                        </label>
                </Col>
            );
        }) */
    } else if(filterMarcas[0] == "NISSAN"){
        mappingTipos = types.map((obj, i) => {
            return(
                <Col key={i} className="p-1">
                    <div onClick={() => navegante(obj, "Tipos")} style={{ minHeight: "50px", cursor: 'pointer' }}>
                        <picture style={{width: "100%", height: "100%"}}>
                        <img
                            src={typesImages[i].image}
                            alt="checkbox"
                            className="w-100"
                            loading="lazy"
                            onMouseOver={(e) => (e.currentTarget.src = typesImages[i].imageChosen)}
                            onMouseOut={(e) => (e.currentTarget.src = typesImages[i].image)}
                        />
                        </picture>
{/*                         <div className={`text-center`} style={{ lineHeight: "15px" }}>
                        <span className={colorModal === true ? "text-filtro-seleccion-black" : "text-filtro-seleccion"}>
                            {obj}
                        </span>
                        </div> */}
                    </div>
                </Col>
            )
        });
    }

    /* const mappingTipos = types.map((obj, i) => {
        return(
            <Col key={i} className="p-1">
                <button variant="outline-primary" onClick={() => navegante(obj, "Tipos")} className="btn boton-filtronuevo" style={{ borderColor: "gray", minHeight: "140px" }}>
                    <picture style={{width: "100%", height: "100%"}}>
                    <img
                        src={typesImages[i].image}
                        alt="checkbox"
                        className="w-100"
                        loading="lazy"
                        onMouseOver={(e) => (e.currentTarget.src = typesImages[i].imageChosen)}
                        onMouseOut={(e) => (e.currentTarget.src = typesImages[i].image)}
                    />
                    </picture>
                    <div className={`text-center`} style={{ lineHeight: "15px" }}>
                    <span className={colorModal === true ? "text-filtro-seleccion-black" : "text-filtro-seleccion"}>
                        {obj}
                    </span>
                    </div>
                </button>
            </Col>
        )
    });
    
    const mappingMarcas = makes.map((obj, i) => {
        return(
            <Col lg={2} xs={5} key={i} className={filterMarcas && filterMarcas.indexOf(obj) !== -1 ? "px-1 m-1 boton-filtronuevo-check" : "px-1 m-1 boton-filtronuevo"}>
                <div className="form-group form-check" style={{position: 'absolute'}}>
                    <input 
                    type="checkbox" 
                    className="form-check-input rounded " 
                    id={`exampleCheckMarcas${i}`} 
                    checked={filterMarcas && filterMarcas.indexOf(obj) !== -1 } 
                    onClick={() => onFilter(obj, "Marcas")}/>
                </div>
                    <label className="form-check-label" for={`exampleCheckMarcas${i}`} style={{position: 'relative'}}>
                        <img
                            src={`https://d3s2hob8w3xwk8.cloudfront.net/makes/${obj.toLowerCase()}.png`}
                            alt="checkbox"
                            className="w-100"
                            loading="lazy"
                        />
                        <div className={`text-center`} style={{ lineHeight: "15px" }}>
                            <span className={"text-filtro-seleccion"}>
                                {obj}
                            </span>
                        </div>
                    </label>
            </Col>
        );
    }) */

    return(
        <Container>
                <Row>
                    <Col className={colorModal === true ? "texto-filtro-titulo-black" : "texto-filtro-titulo"}>
                        <h5 style={{ fontSize: 15}}>Elige el tipo de unidad</h5>
                    </Col>
                </Row>
                <div className="d-md-block d-none">
                    <Row xs={4}>
                        {mappingTipos}
                    </Row>
                    {/* <Row>
                        <Col className={colorModal === true ? "texto-filtro-titulo-black" : "texto-filtro-titulo"}>
                            <h5 style={{ fontSize: 15}}>Marcas</h5>
                        </Col>
                    </Row> */}
{/*                     <Row  style={{display: 'flex', justifyContent: 'center'}}>
                        {mappingMarcas}
                    </Row> */}
                </div> 
                <div className={splitUrl[4] === undefined ? "d-sm-block d-md-none" : "d-none"}>
                    <Row xs={2}>
                        {mappingTipos}
                    </Row>
                    {/*<Row>
                        <Col className={colorModal === true ? "texto-filtro-titulo-black" : "texto-filtro-titulo"}>
                            <h5 style={{ fontSize: 15}}>Marcas</h5>
                        </Col>
                    </Row>*/}
{/*                     <Row  style={{display: 'flex', justifyContent: 'center'}}>
                        {mappingMarcas}
                    </Row> */}
                </div>

                <div className={splitUrl[4] !== undefined ? "d-sm-block d-md-none" : "d-none"}>
                    <Row xs={4}>
                        {mappingTipos}
                    </Row>
                    {/*<Row>
                        <Col className={colorModal === true ? "texto-filtro-titulo-black" : "texto-filtro-titulo"}>
                            <h5 style={{ fontSize: 15}}>Marcas</h5>
                        </Col>
                    </Row>*/}
{/*                     <Row  style={{display: 'flex', justifyContent: 'center'}}>
                        {mappingMarcas}
                    </Row> */}
                </div>  
        </Container>
    )

}